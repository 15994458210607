.badgeselect {
  max-width: 100%; }

.tags {
  width: 100%;
  .tag.badgeselect-tag-more {
    background: none; } }

.badgeselect-menu-header {
  display: flex;
  padding: 15px 30px;
  box-shadow: inset 0 -1px #D8D8D8;
  flex-direction: row;
  align-items: center;
  align-content: space-around;
  justify-content: space-between;
  .badgeselect-menu-header-search {
    width: 140px; } }

.badgeselect-menu-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0 12px 30px;
  .badgeselect-menu-item {
    width: 50%; } }

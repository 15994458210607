@import "../_styles/vars";

.circle {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;

  .spinner-container {
    color: inherit; }

  .spinner {
    width: 20px;
    height: 20px; } }

.circle-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.table-row--active .circle {
  background-color: white;
  box-shadow: $boxShadowElevation1; }

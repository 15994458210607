.multiSelect {
  max-width: 100%;

  .input-field {
    min-height: 29px;
    padding-bottom: 0; }

  .tags {
    width: 100%; } }

.multiSelect-input {
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 60px;
  max-width: 100%;
  line-height: 20px;
  padding: 4px 0 4px; }

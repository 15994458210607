@import "../_styles/vars";
@import "../_styles/keyframes";

.doubleBlindModal {
  opacity: 0;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: $blindModalZindex; }

.doubleBlindModal-blackout {
  transition: opacity $time;
  animation: modalFadeIn $time $easeMd;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-blackout); }

.doubleBlindModal-item {
  height: 100%;
  width: 33.3%;
  max-width: 85%;
  position: absolute;
  right: 0;
  transition: transform $time;
  box-sizing: border-box;
  animation: doubleBlindModal-item $time;

  .card {
    position: relative;
    z-index: 99;
    height: 100%;
    border-radius: 0;
    box-shadow: $boxShadowLeftSide1;
    overflow: auto;
    box-sizing: border-box; }

  .doubleBlindModal-blackout {
    z-index: 100; } }


.doubleBlindModal--opened {
  &.doubleBlindModal {
    opacity: 1; } }

@media screen and (min-width: $min-small-1) {
  .doubleBlindModal-item {
    width: 600px;

    .card {
      width: calc(100% + 50%);
      padding-right: 50%; } } }

@keyframes doubleBlindModal-item {
  from {
    transform: translate3d(100%, 0, 0); }
  to {
    transform: translate3d(0%, 0, 0); } }

@import '../_styles/mixins';

.chips-item {
  color: white;
  background-color: var(--color-text-light);
  border-radius: 9.5px;
  padding: 4px 8px;
  display: inline-block;
  margin-right: 4px;
  font-size: 12px;
  font-weight: 500;

  &:last-child {
    margin-right: 0; } }

@include generateAccentBackgroundSuffics(chips-item);

.chips-item--light {
  background-color: var(--color-background-gray200);
  color: var(--color-text-secondary);
  font-weight: normal; }

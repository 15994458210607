@import "../_styles/vars";

.progress {
  border-radius: 2px;
  display: block;
  width: 70px;
  overflow: hidden;
  position: relative;
  height: 10px;
  z-index: 1;
  background-color: var(--color-background-black10); }

.progress--inline {
  display: inline-block;
  margin-right: 8px;
  width: 32px; }

.progress--indeterminate {
  &:before, &:after {
    content: '';
    position: absolute;
    background-color: var(--color-success);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: left;
    transform: translate3d(0, 0, 0);
    animation: indeterminate 2.1s cubic-bezier(0.66, 0.91, 0.68, 0.66) infinite; }

  &:after {
    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
    animation-fill-mode: both; } }

@keyframes indeterminate {
  from {
    transform: translate3d(-35%, 0, 0) scaleX(.35); }

  60%, to {
    transform: translate3d(100%, 0, 0) scaleX(.9); } }

@keyframes indeterminate-short {
  from {
    transform: translate3d(-200%, 0, 0) scaleX(1); }

  60%, to {
    transform: translate3d(100%, 0, 0) scaleX(.1); } }

.progress--big {
  width: 500px;
  height: 30px;

  .progress-value {
    line-height: 30px;
    font-size: $fontSizeBig; } }

.progress--medium {
  width: 200px;
  height: 16px;

  .progress-value {
    line-height: 16px; } }

.progress-bar {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  background-color: var(--color-success);
  min-width: 1px;
  overflow: hidden;

  > .progress-value {
    color: white; } }

.progress-value {
  position: absolute;
  left: 0;
  z-index: 1;
  top: 0;
  width: 100%;
  line-height: 10px;
  text-align: center;
  font-size: $fontSizeSuperSmall;
  font-weight: bold;
  color: var(--color-progress-text); }

.progress-detail {
  font-weight: 500;
  font-size: 11px; }

@import "../_styles/vars";

.toggle {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  height: 20px;
  transform: translate3d(1px, 1px, 0); }

.toggle-label {
  margin-left: 8px; }

.toggle-box {
  display: block;
  cursor: pointer;
  width: 30px;
  height: 14px;
  margin: 1px 3px 0 0;
  border-radius: 7px;
  position: relative;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: var(--color-background-black10);
  user-select: none;
  transform: translate(0, 0); }

.toggle-box-thumb {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: -3px;
  border-radius: 50%;
  background-color: var(--color-background-gray500);
  box-shadow: $boxShadowElevation1;
  transform: translate3d(-3px, 0, 0);
  transition: transform $time $easeMd; }

.toggle-input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  top: 0;
  left: 0;
  appearance: none; }

.toggle-input:disabled + .toggle-box {
  opacity: .5; }

.toggle-input:checked:not(:disabled) + .toggle-box {
  background-color: var(--color-primary30);

  .toggle-box-thumb {
    background-color: var(--color-primary); } }

.toggle-input:checked + .toggle-box {
  .toggle-box-thumb {
    transform: translate3d(14px, 0, 0); } }

.toggle-input[disabled] + .toggle-box,
.toggle-input[readonly] + .toggle-box {
  cursor: default; }

.toggle-input:indeterminate + .toggle-box {
  background-color: var(--color-background-gray200);

  .toggle-box-thumb {
    background-color: var(--color-background-gray200);
    transform: translateX(5px); } }

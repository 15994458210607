@import "vars";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');

@font-face {
  font-family: 'text-security-disc';
  src: url('../_fonts/text-security-disc.woff') format('woff'), url('../_fonts/text-security-disc.ttf') format('truetype'); }

h1, h2, h3, h4, h5, h6 {
  margin: initial; }

::placeholder {
  color: var(--color-text-secondary); }

html, body, input, textarea, select, button {
  color: var(--color-text);
  font: #{$fontSizePrimary}/1.3 Roboto, sans-serif; }

svg {
  display: inline-block;
  vertical-align: middle; }

hr {
  border: none;
  border-top: 1px solid currentColor;
  opacity: $opacityTextSuperLight;
  margin: 16px 0; }

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none; }

.link {
  color: var(--color-primary);
  text-decoration: underline; }

.empty {
  color: var(--color-text-secondary); }

.danger {
  color: var(--color-text-danger); }

.warn {
  color: var(--color-text-warning); }

.success {
  color: var(--color-text-success); }

.more {
  font-size: $fontSizeSmall;
  color: var(--color-text-secondary);
  font-weight: normal; }

.hidden {
  display: none; }

input, textarea, button {
  background: none;
  border: none;

  &:focus {
    outline: none; } }

.ulist {
  margin: 16px 0;
  line-height: 1.5;
  padding-left: 20px;
  list-style: disc; }

.edit-link {
  cursor: pointer; }

@import "../_styles/vars";

.btns {
  display: inline-flex;
  border-radius: 2px;
  white-space: nowrap;

  &:not(.btn--flat):not(.btn--primary) {
    background-color: var(--color-background-card); }

  &:not(.btn--flat) {
    box-shadow: $boxShadowElevation05; }

  &:not(.btn--small) {
    height: 36px;
    line-height: 36px; }

  > .btn {
    margin: 0;
    height: inherit;
    line-height: inherit;
    color: inherit;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: var(--color-border-table); } }

  > .btn:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    &:after {
      content: none; } }

  > .btn:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; } }

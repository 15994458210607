@import "../_styles/vars";

.form {
  position: relative; }

.form--loading .form-content {
  opacity: .3; }

.form-field {
  display: block;
  position: relative;

  &:not(:first-child) {
    margin-top: 16px; } }

.form-field--error {
  .form-field-label {
    color: var(--color-danger); } }

.form-field-label {
  line-height: 20px;
  margin-bottom: 4px;
  font-size: $fontSizeSmall;
  color: var(--color-field-focus); }

.form-field-error {
  color: var(--color-text-danger);
  font-size: 12px;
  margin-top: 8px;
  display: block;
  word-wrap: break-word; }

.form-content {
  position: relative;
  z-index: 1; }

.form-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; }

.form-field--togglebox, .form-field--checkbox {
  line-height: 24px; }

.form-field--checkbox {
  .spinner-container.btn-icon {
    margin-right: -2px; } }

.form-fields {
  display: flex;
  align-items: flex-start; }

.form-fields-item:not(:first-child) {
  margin-left: 12px; }

.form-section {
  padding-top: 16px; }

.form-section-header {
  margin-top: 28px;
  margin-bottom: 16px;
  font-size: $fontSizeMedium;
  font-weight: 500; }

.form-section-header--withDescription {
  margin-bottom: 0; }

.form-section-description {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: $fontSizeSmall; }

.form-row {
  display: flex;
  height: 60px;
  align-items: center; }

.form-row--highlight {
  background-color: var(--color-background-primary-highlight); }

.form-row--changed {
  background-color: var(--color-enabled); }

.form-row-label,
.form-row-content {
  flex-grow: 1;
  flex-shrink: 1; }

.form-row-content {
  width: 65%; }

.form-row-label {
  width: 35%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.form-field-file-name {
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis; }

.form-field-file-name--empty {
  color: var(--color-text-super-light); }

.form-scroll {
  max-height: calc(100vh - 350px);
  overflow: auto;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  margin: 0 -32px;
  padding: 16px 32px; }

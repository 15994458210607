@import "../_styles/vars";

.page-interactiveBar-container {
  height: 0;
  position: relative;
  z-index: 0;

  @for $i from 1 to 5 {
    .page-interactiveBar:nth-child(#{$i}) {
      z-index: 5 - $i; } } }

.page-interactiveBar {
  position: relative;
  visibility: hidden;
  height: 0;
  transition: visibility $time step-end; }

.page-interactiveBar-content {
  display: flex;
  align-items: center;
  height: $barHeight;
  transition: transform $time linear, box-shadow $time $easeMd;
  background-color: var(--color-enabled);
  font-weight: 500;
  padding: 0 16px;
  transform: translate3d(0, -100%, 0);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity $time ease;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .1), $boxShadowElevation05; }

  .btn {
    margin-left: 16px; } }

.page-interactiveBar--active {
  height: $barHeight;
  visibility: visible;
  transition: visibility $time step-start;

  .page-interactiveBar-content {
    transform: translate3d(0, 0, 0);

    &:after {
      opacity: 1; } } }

.page-interactiveBar-content--success {
  background-color: var(--color-success); }

.page-interactiveBar-content--primary {
  background-color: var(--color-primary);
  color: var(--color-text-white); }

.page-interactiveBar-content--warning {
  background-color: var(--color-background-warning); }

.page-interactiveBar-content--danger {
  background-color: var(--color-danger);
  color: var(--color-text-white); }

@import "../_styles/vars";

.processLoader {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column; }

.processLoader-pane {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  position: relative; }

.processLoader-message {
  font-size: $fontSizeMedium;
  font-weight: 500;
  color: var(--color-text); }

.processLoader-details {
  margin-top: 10px;
  font-size: $fontSizeSmall;
  color: var(--color-text-secondary);
  text-align: center;

  .progress {
    margin-bottom: 8px; } }

.processLoader-icon {
  height: 24px;
  width: 48px;
  color: var(--color-text-light); }

.processLoader-animation {
  margin: 0 12px;
  width: 40px;
  height: 3px;
  position: relative;
  overflow: hidden; }

.processLoader-animation-line {
  width: 6px;
  height: 3px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--color-background-gray400);
  animation: processLoader 4s $ease infinite;
  transform: translateX(-10px);

  @for $i from 1 through 4 {
    &:nth-child(#{$i + 1}) {
      animation-delay: $i * .2s; } } }

@keyframes processLoader {
  from, to, 75% {
    transform: translate3d(-6px, 0, 0); }

  25%, 50% {
    transform: translate3d(40px, 0, 0); } }

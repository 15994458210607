@import "../_styles/vars";

.card {
  background-color: var(--color-background-card);
  box-shadow: $boxShadowElevation1;
  border-radius: 2px;
  position: relative; }

.card--selected {
  background-color: var(--color-enabled); }

.card-close {
  position: absolute;
  right: 4px;
  top: 4px;
  line-height: 32px;
  height: 32px;
  width: 32px;
  text-align: center;
  color: var(--color-text-secondary);
  font-size: 24px;
  z-index: 2; }

.card-close--circle {
  background-color: var(--color-background-black30);
  border-radius: 100%;
  right: 8px;
  top: 8px;
  color: white;

  svg {
    margin-top: -2px; } }

.card--flat {
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  box-shadow: none;
  border-radius: 0;
  margin-top: 8px;

  &:first-child {
    border-top: none;
    margin-top: 0; } }

.card-header {
  padding: 24px 32px;
  font-size: $fontSizeMedium;
  font-weight: 500;
  text-transform: uppercase;

  .btn {
    margin-left: 8px; }

  + .card-content {
    padding-top: 0; } }

.card-header--withBack {
  position: relative;
  padding-left: 64px;

  .card-header-back {
    position: absolute;
    left: 18px;
    top: 15px; } }

[dir="rtl"] .card-header--withBack .btn-icon {
  transform: rotate(180deg); }

.card-content {
  padding: 32px; }

.card-modal-error {
  .card-content {
    padding-bottom: 0px; } }

.card-actions {
  padding: 8px 16px 16px;
  text-align: right;
  border-radius: 0 0 2px 2px;

  .btn {
    &:not(:last-child) {
      margin-right: 8px; } } }

.card-actions--justify {
  display: flex;
  justify-content: space-between; }

@media screen and (min-width: $small-1) {
  .card-actions {
    .btn {
      min-width: 120px; } } }

@import "../_styles/vars";

.timePicker {
  font-size: $fontSizeLarge;
  font-weight: bold;
  padding: 24px;
  user-select: none; }

.timePicker-content {
  margin: 64px 0;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  text-align: center; }

.timePicker-section {
  padding: 4px;
  width: 32px;
  position: relative; }

.timePicker-vals {
  position: absolute;
  left: 0;
  width: 100%;
  font-size: $fontSizePrimary;
  line-height: 1.7;
  font-weight: 500; }

.timePicker-vals--prev {
  bottom: 100%;
  margin-bottom: 4px;

  .timePicker-val:first-child {
    font-size: 80%;
    opacity: .4; }

  .timePicker-val:nth-child(2) {
    font-size: 90%;
    opacity: .55; } }

.timePicker-vals--next {
  top: 100%;
  margin-top: 4px;

  .timePicker-val:last-child {
    font-size: 80%;
    opacity: .4; }

  .timePicker-val:nth-child(2) {
    font-size: 90%;
    opacity: .55; } }

.timePicker-val {
  cursor: pointer;
  opacity: .61; }

.timePicker-delim {
  width: 1em;
  margin: 0 4px;
  color: var(--color-text-secondary); }

.timePicker-space {
  width: .6em; }

@import "../_styles/vars";
@import "../_styles/basic";

.input {
  display: block;
  position: relative;

  &:after {
    content: '';
    background-color: var(--color-field-focus);
    bottom: 0;
    height: 2px;
    left: 45%;
    position: absolute;
    transition-duration: .2s;
    transition-timing-function: $easeMd;
    visibility: hidden;
    width: 10px; } }

.input--secure.input--dirty .input-field {
  font-family: 'text-security-disc'; }

.input--label {
  padding: 20px 0 0;

  .input-icon {
    top: 32px; } }

.input-field {
  display: block;
  line-height: 20px;
  border-bottom: 1px solid var(--color-field-border);
  width: 100%;
  padding: 0 0 4px;

  &[readonly],
  &[disabled] {
    color: var(--color-text-super-light);

    + .input-icon {
      opacity: .5; } } }

textarea.input-field {
  resize: none;
  min-height: 2.5em; }

.input .form-field-label {
  position: absolute;
  font-size: $fontSizeMedium;
  pointer-events: none;
  left: 0;
  right: 0;
  height: 20px;
  top: 24px;
  bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: top .2s $easeMd, color .2s $easeMd;
  line-height: 1; }

.input--focus:after {
  left: 0;
  visibility: visible;
  width: 100%; }

.input:not(.input--dirty):not(.input--focus) {
  .form-field-label {
    color: var(--color-text-light); } }

.input--dirty, .input--focus {
  .form-field-label {
    font-size: 12px;
    top: 4px; } }

.form-field--error .input {
  &:after {
    background-color: var(--color-text-danger); }

  &:not(.input--dirty):not(.input--focus) .form-field-label {
    color: var(--color-text-danger); } }

.input--iconRight {
  .input-input {
    margin-right: 50px; } }

.input-icon {
  position: absolute;
  right: 4px;
  top: 12px;
  transform: translateY(-50%);
  bottom: 20px;
  height: 12px; }

.input-icon--action {
  cursor: pointer; }

.btn.input-additional {
  position: absolute;
  right: -8px;
  bottom: 0;
  top: initial;

  .btn-content {
    font-size: 13px;
    text-transform: none; } }

.input--additional.input--iconRight {
  .input-additional {
    right: 26px; }

  .input-field {
    padding-right: 89px;
    box-sizing: border-box; } }

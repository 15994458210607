@import "../_styles/vars";

.list {
  line-height: 1.5; }

.list--icons {
  .list-item {
    padding-left: 32px; } }

.list--twoCols {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  .list-item {
    width: 50%;
    padding-right: 32px;

    &:first-child + .list-item {
      margin-top: 0; } } }

.list-item {
  position: relative;
  margin-top: 24px;

  .hint {
    font-weight: normal; }

  &:first-child {
    margin-top: 0; } }

.list-item-icon {
  position: absolute;
  left: 0;
  top: .7em;
  width: 24px;
  color: var(--color-text-super-light);
  transform: translateY(-50%); }

.list-item-info {
  font-size: $fontSizePrimary; }

@import "../_styles/vars";

.menu {
  padding: 16px 0;
  min-width: 200px;
  text-align: left;
  max-height: 60vh;
  overflow-x: auto;
  user-select: none; }

.menu-item {
  padding: 0 24px;
  min-height: 32px;
  line-height: 32px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .definition-title {
    font-weight: normal; } }

.menu-item--hover {
  background-color: var(--color-background-gray200); }

.menu-item--disabled {
  cursor: default;
  color: var(--color-text-secondary);

  &:hover {
    background-color: inherit; } }

.menu-item--uppercase {
  text-transform: uppercase; }

.menu-item-icon {
  width: 16px;
  height: 16px;
  margin-right: 16px;
  opacity: $opacityTextSecondary; }

.menu-delimiter {
  margin: 8px 0 7px;
  border-top: 1px solid var(--color-border-table);

  &:first-child,
  &:last-child {
    display: none; } }

.menu-header {
  padding: 0 24px;
  min-height: 32px;
  line-height: 32px;
  font-size: $fontSizeSmall;
  font-weight: 500;
  margin-top: 16px;
  color: var(--color-text-secondary);
  text-transform: uppercase; }

@import "../_styles/vars";

.calendar {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  height: 100%;

  .scroll {
    flex-shrink: 1; } }

.calendar-header {
  margin: 8px 12px 16px 4px;

  .btns {
    margin-right: 8px;

    &:not(:first-child) {
      margin-left: 8px; } } }

.calendar-header-title {
  .btn-content {
    min-width: 72px;
    padding: 0 16px; }

  &.btn--disabled {
    .btn-content {
      opacity: 1; } } }

[dir="rtl"] .calendar-header {
  .btns svg {
    transform: rotate(180deg); } }

.calendar-month {
  min-height: 100%;
  display: flex;
  flex-flow: column;

  .calendar-week {
    flex-grow: 1;
    flex-basis: 20%;
    min-height: 68px; } }

.calendar-year {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 25%);
  grid-template-rows: repeat(3, calc(100% / 3));

  .calendar-item {
    margin-top: 8px; } }

.calendar-year-monthPerYear {
  height: 100%;
  display: flex;
  flex-direction: column;

  &--active {
    .calendar-year-monthPerYear-title:hover .calendar-year-monthPerYear-title-entry,
    .calendar-year-monthPerYear-title-entry {
      background-color: var(--color-primary);
      color: var(--color-text-white); }

    .calendar-year-monthPerYear-title:hover .calendar-year-monthPerYear-title-entry {
      background-color: var(--color-primary30); } } }

.calendar-year-monthPerYear-title {
  padding-bottom: 8px;
  border-bottom: 2px solid var(--color-border);
  cursor: pointer;

  &:hover {
    .calendar-year-monthPerYear-title-entry {
      background-color: var(--color-primary30);
      color: var(--color-text-highlight); } } }

.calendar-year-monthPerYear-content {
  overflow: auto;
  scrollbar-width: thin;
  flex-grow: 1;
  margin: 12px 0 14px; }

.calendar-year-monthPerYear-title-entry {
  border-radius: 11px;
  padding: 4px 6px;
  color: var(--color-text-secondary);
  font-weight: 500;
  font-size: 12px;
  transition: color $time, background-color $time;
  display: inline-block; }

.calendar-week {
  padding-bottom: 8px;
  display: flex;
  flex-flow: row;
  border-bottom: 1px solid var(--color-border); }

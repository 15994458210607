@mixin commonRadioCheck {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none; }

@mixin radioCheckDisabled {
  cursor: default; }

@mixin radioCheckBox {
  .ink {
    position: absolute;
    width: 42px;
    height: 42px;
    border-radius: 100%; } }

@mixin radioCheckInput {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  left: -100000px;
  appearance: none; }

@mixin radioCheckDisabledRadioCheckBox {
  color: var(--color-text-super-light);
  cursor: default; }

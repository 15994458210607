@import "../_styles/vars";

.datepicker {
  width: 200px;
  line-height: 20px;
  user-select: none;
  padding: 12px; }

.datepicker-week {
  display: flex; }

.datepicker-nav {
  display: flex;
  text-align: center;
  padding: 2px;
  margin-bottom: 8px; }

.datepicker-nav-item {
  width: 30px; }

[dir="rtl"] .datepicker-nav-item {
  svg {
    transform: rotate(180deg); } }

.datepicker-nav-title {
  flex-grow: 1;
  text-transform: capitalize; }

.datepicker-days {
  font-size: $fontSizeSmall; }

.datepicker-day {
  width: 16px;
  margin: 1px;
  text-align: center;
  flex-grow: 1;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    background-color: var(--color-background-gray200); } }

.datepicker-day--outside {
  color: var(--color-text-secondary); }

.datepicker-day--current {
  font-weight: bold;
  color: var(--color-primary); }

.datepicker-day--selected {
  background-color: var(--color-primary);
  color: white;
  position: relative;

  &:hover {
    background-color: var(--color-primary-darken); }

  + .datepicker-day--selected:before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left: -3px;
    width: 4px;
    background-color: var(--color-primary);
    z-index: -1; } }

.datepicker--disabled {
  color: var(--color-text-super-light);
  cursor: default;

  &:hover {
    background-color: transparent; } }

.datepicker-years {
  display: flex;
  text-align: center;
  margin-top: 8px; }

.datepicker-year--current {
  color: var(--color-primary);
  font-weight: 500; }

.datepicker-year {
  flex-grow: 1;
  font-size: $fontSizeSmall; }

.datepicker-week--dayNames {
  font-weight: 500;
  color: var(--color-primary);
  font-size: $fontSizeSuperSmall;
  text-transform: uppercase;

  .datepicker-day:hover {
    cursor: default;
    background-color: transparent; } }

.chips {
  display: block;

  .chips-item {
    margin-bottom: 4px; } }


.chips--wide {
  .chips-item {
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 14px; } }

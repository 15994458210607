@import "../_styles/vars";

.radiobox-label {
  margin-left: 8px;
  cursor: pointer; }

.radiobox-label--disabled {
  cursor: default;
  color: var(--color-text-secondary); }

.radiobox-label--readOnly {
  cursor: default; }

@import "../_styles/vars";

.blindModal {
  opacity: 0;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: $blindModalZindex; }

.blindModal-blackout {
  opacity: 0;
  transition: opacity $time;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-blackout); }

.blindModal-content {
  height: 100%;
  width: 33.3%;
  max-width: 85%;
  position: absolute;
  z-index: 0;
  right: 0;
  transform: translate3d(100%, 0, 0);
  transition: transform $time;
  box-sizing: border-box;

  .card {
    position: relative;
    z-index: 99;
    height: 100%;
    border-radius: 0;
    box-shadow: $boxShadowLeftSide1;
    overflow: auto; } }

.blindModal--opened {
  &.blindModal {
    opacity: 1; }

  .blindModal-blackout {
    opacity: 1; }

  .blindModal-content {
    transform: translate3d(0, 0, 0); } }

.blindModal--closing {
  .blindModal-blackout {
    opacity: 0; }

  .blindModal-content {
    transform: translate3d(100%, 0, 0); } }

.blindModal--customWidth {
  .blindModal-content {
    max-width: 100%!important; } }

@media screen and (min-width: $min-small-1) {
  .blindModal-content {
    width: 600px; }

  .blindModal--large {
    .blindModal-content {
      width: 66.6%; } } }

@media screen and (min-width: $min-medium-2) {
  .blindModal-content {
    max-width: 900px; } }

@media screen and (min-width: $min-large-1) {
  .blindModal-content {
    max-width: 1200px; } }

@media screen and (min-width: $min-large-2) {
  .blindModal-content {
    max-width: 1300px; } }

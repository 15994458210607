@import "../_styles/vars";

.snackBar {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  bottom: 0;
  background-color: var(--color-background-tooltip);
  padding: 12px 16px;
  z-index: 100;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: snackShow $time $easeMd;

  > .btn {
    flex-shrink: 0;
    color: var(--color-primary); } }

.snackBar-text {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
  line-height: 1.4; }

@keyframes snackShow {
  from {
    transform: translate(-50%, 100%); }

  to {
    transform: translate(-50%, 0%); } }

@media screen and (min-width: $small-1) {
  .snackBar {
    width: 500px; } }

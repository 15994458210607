@import "../_styles/vars";

.comment {
  display: flex;
  padding: 6px 12px;
  align-items: flex-start;
  opacity: 1;
  transition: opacity $time $ease;

  &:hover {
    .comment-more {
      opacity: 1; } }

  .ava {
    margin: 4px 0;
    flex-grow: 0;
    flex-shrink: 0; } }

.comment--isNew {
  opacity: .5;
  animation: newComment .2s ease-out; }

@keyframes newComment {
  from {
    opacity: 0;
    transform: translateY(20px); }

  to {
    opacity: .5;
    transform: translateY(0); } }

.comment--mine {
  margin-left: 64px;
  flex-flow: row-reverse;

  .comment-text {
    border-radius: 2px 0 2px 2px;
    color: white;
    background-color: var(--color-primary);

    &:before {
      right: -8px;
      left: auto;
      border-width: 10px 8px 0 0;
      border-top-color: var(--color-primary); } } }

.comment-content {
  margin-left: 12px; }

.comment-text {
  line-height: 1.4;
  padding: 8px 12px;
  border-radius: 0 2px 2px 2px;
  background-color: var(--color-background-gray200);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    top: 0;
    border: solid transparent;
    border-width: 10px 0 0 8px;
    border-top-color: var(--color-background-gray200); } }

.comment-date {
  font-size: 11px;
  color: var(--color-text-super-light);
  margin-top: 4px; }

.comment-more {
  opacity: 0;
  transition: opacity $time $easeMd; }

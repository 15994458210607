@import "../_styles/vars";

.bar {
  height: $barHeight;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  > .btn,
  > .btns,
  > .dropDownBtn,
  > .search-bar {
    &:not(:first-child) {
      margin-left: 7px; } }

  > .title:not(:first-child) {
      margin-left: 16px; }

  > .tabs:not(:first-child) {
    margin-left: 16px;
    box-shadow: none; } }

.bar-item {
  padding: 0 8px; }

.bar--children-left {
  justify-content: flex-start; }

.bar--children-center {
  justify-content: center; }

.bar--children-right {
  justify-content: flex-end; }

.bar--divider {
  padding-bottom: 10px;
  box-shadow: inset 0 -1px var(--color-border); }

.bar-space {
  flex-grow: 1; }

@import "../_styles/vars";

$tagIconWidth: 12px;
$tagIconHeight: 12px;

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 100%;
  width: fit-content; }

.tag {
  background-color: var(--color-background-gray200);
  border: 0;
  border-radius: 2px;
  height: 24px;
  margin-bottom: 4px;
  line-height: 24px;
  margin-right: 4px;
  padding: 0 8px;
  position: relative;
  white-space: nowrap;
  max-width: 100%; }

.tag-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%; }

.tag-icon {
  border-radius: 8px;
  cursor: pointer;
  width: $tagIconWidth;
  height: $tagIconHeight;
  opacity: $opacityTextSecondary;
  vertical-align: middle;
  margin-left: 4px;
  margin-right: -2px;
  .tag-content {
    max-width: calc(100% - #{$tagIconWidth}); } }

@import "../_styles/vars";

.calendar-item {
  max-width: 200px;
  white-space: nowrap;
  margin-top: 4px;
  border-radius: 2px;
  position: relative; }

.calendar-item--interactive {
  cursor: pointer;

  .calendar-item-box:hover:before {
    opacity: .15; } }

.calendar-item--clickable {
  cursor: pointer; }

.calendar-week--dayNames {
  padding-bottom: 8px;
  padding-left: 4px;
  border-bottom-width: 2px;
  flex-grow: 0;
  font-weight: 500;
  color: var(--color-text-secondary);
  font-size: $fontSizeSmall; }

.calendar-day {
  flex-grow: 1;
  padding-right: 12px;
  width: calc(100% / 7); }

$dayNumberBoxSize: 22px;

.calendar-day-number {
  height: $dayNumberBoxSize;
  width: $dayNumberBoxSize;
  text-align: center;
  font-size: $fontSizeSmall;
  margin: 4px 0 4px;
  display: block;
  line-height: $dayNumberBoxSize; }

.calendar-day-number--today {
  background-color: var(--color-primary);
  color: var(--color-text-white);
  border-radius: 50%;
  font-weight: 500; }

.calendar-item-box {
  padding: 0 4px;
  border-radius: 2px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .25;
    transition: opacity $time ease; } }

.calendar-item-box-collectiveShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  top: 3px;
  left: 2px;
  background-color: inherit;
  z-index: -1;
  opacity: .25;

  & + & {
    top: 6px;
    left: 4px;
    z-index: -2;
    opacity: .25; } }


.calendar-item-content {
  height: 24px;
  display: flex;
  align-items: baseline;
  line-height: 24px;
  position: relative;
  z-index: 1;
  color: var(--color-text-white); }

.calendar-item-time {
  font-size: $fontSizeSuperSmall;
  opacity: $opacityTextSecondary;
  margin-right: 6px; }

.calendar-item-title {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis; }

.calendar-item-icon {
  margin-left: 6px;
  flex-shrink: 0;
  flex-grow: 0; }

.pager {
  display: flex;
  align-items: center; }

[dir="rtl"] {
  .pager-prev, .pager-next {
    .btn-icon {
      transform: rotate(180deg); } } }

.pager-perPage--selected {
  font-weight: bold; }

.pager-text {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px; }

@import "../_styles/vars";

.form-field--togglebox {
  line-height: 24px; }

.checkbox-label {
  margin-left: 8px;
  cursor: pointer; }

.checkbox-label--disabled {
  cursor: default;
  color: var(--color-text-secondary); }

.checkbox-label--readOnly {
  cursor: default; }

@import "../_styles/vars";

.page {
  height: 100%;
  width: 800px;
  flex-grow: 1; }

.page-top {
  padding: 0 16px;
  background-color: var(--color-background);
  position: relative;
  z-index: 3;

  > .search:first-child,
  > .bar:first-child {
    margin: 8px 44px 0 0; }

  > .bar:last-child,
  > .search:last-child {
    margin-bottom: 12px; }

  > .bar {
    margin: 12px 0; }

  > .tabs {
    margin: 8px -16px 0;
    padding: 0 16px; } }

.page-content {
  height: 200px;
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 16px;

  > .table {
    margin-right: 16px; }

  > .scroll > .table {
    margin-top: 16px; }

  > .layout {
    margin-left: -16px;
    padding-left: 16px; } }

.page-content--animated {
  transition: transform $time linear, margin $time step-start; }

.page-interactiveBar-container {
  height: 0;
  position: relative;
  z-index: 0;

  @for $i from 1 to 5 {
    .page-interactiveBar:nth-child(#{$i}) {
      z-index: 5 - $i; } } }

.page-interactiveBar {
  position: relative;
  visibility: hidden;
  height: 0;
  transition: visibility $time step-end; }

.page-interactiveBar-content {
  display: flex;
  align-items: center;
  height: $barHeight;
  transition: transform $time linear, box-shadow $time $easeMd;
  background-color: var(--color-enabled);
  font-weight: 500;
  padding: 0 16px;
  transform: translate3d(0, -100%, 0);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity $time ease;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .1), $boxShadowElevation05; }

  .btn {
    margin-left: 16px; } }

.page-interactiveBar--active {
  height: $barHeight;
  visibility: visible;
  transition: visibility $time step-start;

  .page-interactiveBar-content {
    transform: translate3d(0, 0, 0);

    &:after {
      opacity: 1; } } }

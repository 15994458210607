@import "../_styles/vars";
@import "../_styles/inputs";

.radio {
  @include commonRadioCheck; }

.radio--disabled {
  @include radioCheckDisabled; }

.radio-input {
  @include radioCheckInput; }

.radio-box {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block;
  vertical-align: -2px;
  @include radioCheckBox;

  .ink {
    top: -13px;
    left: -13px; } }

.radio-box-outer {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid;
  border-radius: 8px; }

.radio-box-inner {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.001);
  border-radius: 8px;
  transition: transform $easeMd $time,background-color $easeMd $time; }

.radio-input:checked:not([disabled]) + .radio-box:not(.radio-box--greyscale) {
  color: var(--color-primary); }

.radio-input[type="radio"]:checked + .radio-box {
  .radio-box-inner {
    transform: scale(.5);
    background-color: currentColor; } }

@import "../_styles/vars";

.ava {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #49CE41;
  line-height: 35px;
  text-align: center;
  font-weight: bold;
  cursor: default;

  &:not(.ava--unassigned) {
    color: white; } }

.ava--small {
  width: 24px;
  height: 24px;
  line-height: 26px;
  font-size: $fontSizeSmall; }

.ava--large {
  width: 44px;
  height: 44px;
  line-height: 46px;
  font-size: $fontSizeBig; }

.ava--interactive {
  cursor: pointer; }

.ava--unassigned {
  position: relative;
  background-color: transparent;
  box-shadow: inset 0 0 0 3px currentColor;
  opacity: .1;
  transform: scale(0.8) rotate(45deg);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1px;
    right: 1px;
    margin-top: -1px;
    height: 3px;
    background-color: currentColor; } }

.fab {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  line-height: 48px;

  .btn-content {
    padding: 0; }

  .btn-icon {
    max-width: 24px;
    max-height: 24px; } }

.fab--mini {
  width: 32px;
  height: 32px;
  line-height: 32px;

  .btn-icon {
    max-width: 18px;
    max-height: 18px; } }
